import React, {Component} from 'react';
import '@ionic/core/css/core.css';
import '@ionic/core/css/ionic.bundle.css';
import { IonApp, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonButton } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Home.css';
import axios from 'axios';

class Home extends Component {
  API_KEY = '176ae077e2d34244b40439f1ca7fc033'
  API_URL = 'https://newsapi.org/v2/top-headlines?country=us&apiKey=API_KEY'

  state ={
    items: []
  }

  componentDidMount() {
    axios.get(this.API_URL).then(response => response.data)
    .then((data) => {
      this.setState({ items: data.articles })
      console.log(this.state.items)
    })
  }
  render() {
    return (
      <IonApp>
        <IonHeader>
          <IonToolbar color="primary"> <IonTitle>News APP v1.0</IonTitle></IonToolbar>
        </IonHeader>
        <IonContent>
          {this.state.items.map((item) => (
            <IonCard>
              <img src={item.urlToImage} />
              <IonCardHeader>
                <IonCardTitle>
                  {item.title}
                </IonCardTitle>
                <IonCardSubtitle>
                  {item.auther}
                </IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <p>{item.content}</p>
                <IonButton href={item.url}>Read</IonButton>
              </IonCardContent>
            </IonCard>
          ))}
        </IonContent>
      </IonApp>
    )
  }
}

export default Home;